<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import printInvoice from '@/components/print-invoice'
import printInvoiceadjustment from '@/components/print-invoiceadjustment'

export default {
  page: {
    title: "Customer List",
    items: [],
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  setup () {
    

    return {
    }
  },
  data() {
    return {
      
    };
  },
  computed: {
  },
  methods: {
    createInvoice () {
      printInvoice(1)
    },
    createInvoiceadjustment () {
      printInvoiceadjustment(1)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <b-button id="invprint" variant="success" @click="createInvoice">Print</b-button>
          <b-button id="invprint2" variant="success" @click="createInvoiceadjustment">Print Adjustment</b-button>
        </div>
      </div>
    </div>
  </Layout>
</template>