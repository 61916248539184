import { jsPDF } from "jspdf";
//import { Base64 } from 'js-base64';
import useHttp from '@/comp-functions/useHttp'
import {formatNumber} from '@/utils/formatter'
export default function printInvoiceadjustment(props) {

  const { $get } = useHttp()
  const getData = async () => {
    const {data} = await $get({ url: 'master/invoice/' + props})
    generateLabel(data)
  }
  getData()
  const generateLabel = (data) => {
    let pdfName = 'test'; 
    let curx = 0;
    let cury = 0;
    let doc = new jsPDF();
    let imgurl = new Image();
    //img.src = 'data:image/png;base64,'+ Base64.encode(process.env.VUE_APP_BASEURL + require("@/assets/images/logo-light.png"));
    imgurl.crossOrigin = "Anonymous";
    imgurl.src = process.env.VUE_APP_BASEURL + require("@/assets/images/logo-light.png")
    doc.addImage(imgurl, "PNG", 10, 10, 28, 22.5);
    doc.setFontSize(10);

    cury = 26
    curx = 195
    doc.setFont("helvetica", "bold");
    doc.text("PT Cakrawala Logistik Pratama", curx, cury, {align: "right"});
    cury+=3;
    doc.setFont("helvetica", "normal");
    doc.text("Jl. Angkasa I No.7, Bandara Halim Perdanakusuma, Jakarta", curx, cury, {align: "right"});
    cury+=3;
    doc.text("Phone : 021 - 29837470", curx, cury, {align: "right"});
    cury+=3;
    doc.text("www.antarport.id", curx, cury, {align: "right"});

    cury = 39
    curx = 10
    doc.setFont("helvetica", "bold");
    doc.text("INVOICE # " + data.inv_number, curx, cury);
    cury+=4;
    doc.setFont("helvetica", "normal");
    doc.text("Invoice Date :" + data.order.ord_no, curx, cury);
    cury+=4;
    doc.text("Order # : " + data.order.ord_no, curx, cury);
    cury+=5.5;
    doc.setFont("helvetica", "bold");
    doc.text("Bill to :", curx, cury);
    cury+=4;
    doc.setFont("helvetica", "normal");
    doc.text(data.customer.name, curx, cury);
    cury+=4;
    doc.text(data.customer.address, curx, cury);

    doc.setDrawColor(0);
    doc.setFillColor(241, 241, 241);
    doc.rect(10, 68, 190, 9, "F");

    cury = 73
    
    doc.setFont("helvetica", "bold");
    doc.text("Description", 40, cury);
    // doc.text("Freight Charge", 150, cury);
    // doc.text("Other Charge", 150, cury);
    doc.text("Total (Rp)", 180, cury);

    cury = 81.5
    doc.setFont("helvetica", "normal");
    doc.text(data.ind_description, 12, cury);
    doc.text(data.ind_amount, 198, cury, {align: "right"});
    cury+=6;
    doc.setFont("helvetica", "bold");
    doc.text("Grand Total", 160, cury, {align: "right"});
    doc.text(formatNumber(data.inv_gtotalcharge), 198, cury, {align: "right"});

    doc.setDrawColor(241, 241, 241);
    doc.setLineWidth(0.3);
    doc.line(10, 83, 200, 83);

    curx = 12
    cury+=10;
    
    doc.setFont("helvetica", "bold");
    doc.text("Notes :", curx, cury);
    cury+=4;
    doc.setFont("helvetica", "normal");
    doc.text("Adjustment from Invoice #INV12345", curx, cury);

    cury+=10;

    doc.setDrawColor(0);
    doc.setFillColor(255, 51, 51);
    doc.rect(140, (cury) , 39, 9, "F");
    doc.text("UNPAID", 153, (cury+5.3) );
    
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(curx, cury, 78, 35, 3, 3, "FD");

    cury+=5;
    curx+=37;

    doc.setFont("helvetica", "bold");
    doc.text("Make All Payment", curx, cury, {align: "center"});
    cury+=5.3;
    doc.text("Bank Central Asia ACC No. 6043119988", curx, cury, {align: "center"});
    cury+=5.3;
    doc.text("Bank Central Asia KCU Alam Sutra", curx, cury, {align: "center"});
    cury+=5.3;
    doc.text("PT. Cakrawala Logistic Pratama", curx, cury, {align: "center"});
    cury+=5.3;
    doc.text("Thank You For Your Business", curx, cury, {align: "center"});
    cury+=5.5;
    doc.text("NPWP No. : 79.627.627.7.416.000", curx, cury, {align: "center"});

    doc.save(pdfName + props + '.pdf');
}
}

/*
A4- 210 × 297 mm or 8.3 × 11.7 inches
Default export is a4 paper, portrait, using millimeters for units

// Landscape export, 2×4 inches
const doc = new jsPDF({
  orientation: "landscape",
  unit: "in",
  format: [4, 2]
});

doc.text("Invoice", 10, 40,{align: "right"});

// add the font to jsPDF
doc.addFileToVFS("MyFont.ttf", myFont);
doc.addFont("MyFont.ttf", "MyFont", "normal");
doc.setFont("MyFont");





    let tableData = function(amount) {
        let result = [];
        let data = {
          coin: "100",
          game_group: "GameGroup",
          game_name: "XPTO2",
          game_version: "25",
          machine: "20485861",
          vlt: "0"
        };
        for (let i = 0; i < amount; i += 1) {
          data.id = (i + 1).toString();
          result.push(Object.assign({}, data));
        }
        return result;
      };
      
      function createHeaders(keys) {
        let result = [];
        for (let i = 0; i < keys.length; i += 1) {
          result.push({
            id: keys[i],
            name: keys[i],
            prompt: keys[i],
            width: 65,
            align: "center",
            padding: 0
          });
        }
        return result;
      }
      
      let headers = createHeaders([
        "id",
        "coin",
        "game_group",
        "game_name",
        "game_version",
        "machine",
        "vlt"
      ]);

    doc.table(10, 70, tableData(3), headers, {autoSize: true, fontSize: 10});
*/
