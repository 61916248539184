import { jsPDF } from "jspdf";
import useHttp from '@/comp-functions/useHttp'
import moment from 'moment'
//import { Base64 } from 'js-base64';
// import { ref } from '@vue/composition-api'
import {formatNumber} from '@/utils/formatter'
export default function printInvoice(props) {

  const { $get } = useHttp()

  const getData = async () => {
    const datainv = await $get({ url: 'master/invoice/' + props})
    const data1 = datainv.data
    const {data} = await $get({ url: 'master/vendorconfigall/'+data1.invSchedule.sch_vendor_id +'/'+data1.invSchedule.sch_origin_port_id+'/'+data1.invSchedule.sch_aircraft_type_code})
    generateLabel({...data1,...data})
  }

  getData()
  const generateLabel = (data) => {
    let pdfName = 'Invoice'; 
    let dtlsurchargedesc = '';
    let curx = 0;
    let cury = 0;
    let doc = new jsPDF();
    //let img = new Image();
    //img.src = 'data:image/png;base64,'+ Base64.encode(process.env.VUE_APP_BASEURL + require("@/assets/images/logo-light.png"));

    let imgurl = new Image()
    imgurl.src = process.env.VUE_APP_BASEURL + require("@/assets/images/logo-light.png")
    doc.addImage(imgurl, "PNG", 10, 10, 28, 22.5);
    doc.setFontSize(10);

    // console.log(imgurl)

    cury = 23
    curx = 195
    doc.setFont("times", "bold");
    doc.text("PT Cakrawala Logistik Pratama", curx, cury, {align: "right"});
    cury+=3;
    doc.setFont("times", "normal");
    doc.text("Jl. Angkasa I No.7, Bandara Halim Perdanakusuma, Jakarta", curx, cury, {align: "right"});
    cury+=3;
    doc.text("Phone : 021 - 29837470", curx, cury, {align: "right"});
    cury+=3;
    doc.text("www.antarport.id", curx, cury, {align: "right"});

    doc.setFontSize(9);
    cury+=9;
    doc.setFont("times", "bold");
    doc.text("Shipper :", curx, cury, {align: "right"});
    cury+=3.5;
    doc.setFont("times", "normal");
    doc.text(`${data.order.ord_shipper_name} / ${data.order.ord_shipper_phone}` , curx, cury, {align: "right"});
    cury+=3.5;
    doc.text(data.order.ord_shipper_address, curx, cury, {align: "right"});

    cury+=4.7;
    doc.setFont("times", "bold");
    doc.text("Consignee :", curx, cury, {align: "right"});
    cury+=3.5;
    doc.setFont("times", "normal");
    doc.text(`${data.order.ord_consignee_name} / ${data.order.ord_consignee_phone}`, curx, cury, {align: "right"});
    cury+=3.5;
    doc.text(data.order.ord_consignee_address, curx, cury, {align: "right"});

    cury = 39
    curx = 10
    doc.setFontSize(10);
    doc.setFont("times", "bold");
    doc.text("Invoice# " + data.inv_number, curx, cury);
    cury+=4;
    doc.setFont("times", "normal");
    doc.text("Invoice Date : " + data.inv_date, curx, cury);
    cury+=4;
    doc.text("Order# : " + data.order.ord_no , curx, cury);
    cury+=5.5;
    doc.setFont("times", "bold");
    doc.text("Bill to :", curx, cury);
    cury+=4;
    doc.setFont("times", "normal");
    doc.text(data.customer.name, curx, cury);
    cury+=4;
    doc.text(data.customer.address, curx, cury);

    doc.setDrawColor(0);
    doc.setFillColor(241, 241, 241);
    doc.rect(10, 68, 190, 9, "F");

    cury = 73
    doc.setFont("times", "bold");
    doc.text("Description", 40, cury);
    // doc.text("Freight Charge", 150, cury);
    // doc.text("Other Charge", 150, cury);
    doc.text("Total (Rp)", 180, cury);


    cury = 81.5
    doc.setFont("times", "normal");
    // doc.text("General Cargo 3 x 100Kg", 12, cury);

    data.invoiceDetail.forEach(rowdetails => {
    if(data.inv_type !== 'TYPINVD') {
        dtlsurchargedesc = ''
      if(rowdetails.ind_surchargepct>0) dtlsurchargedesc = `, Surcharge ${parseInt(rowdetails.ind_surchargepct)}%`
      doc.text(`${rowdetails.goodsType.descr_id} ${rowdetails.ind_collies} x ${formatNumber(rowdetails.ind_weightround)}Kg${dtlsurchargedesc}`, 12, cury)
    }else{
      doc.text(`${rowdetails.ind_description} `, 12, cury)
    }
    doc.text(formatNumber (rowdetails.ind_totalcharge), 198, cury, {align: "right"});
    doc.setDrawColor(241, 241, 241);
    doc.setLineWidth(0.3);
    doc.line(10, cury+2, 200, cury+2);
    cury+=7;
    });
    //cury+=6;
    doc.text("Sub Total", 162, cury, {align: "right"});
    doc.text(formatNumber (parseInt(data.inv_freightcharge)+parseInt(data.inv_surcharge)+parseInt(data.inv_handlingcharge)), 198, cury, {align: "right"});
    
    if(data.inv_handlingdgcharge>0){
      cury+=5;
      doc.text("Handling DG", 162, cury, {align: "right"});
      doc.text(formatNumber (data.inv_handlingdgcharge), 198, cury, {align: "right"});
    }
    if(data.inv_adminfee>0){
      cury+=5;
      doc.text("Admin", 162, cury, {align: "right"});
      doc.text(formatNumber(data.inv_adminfee), 198, cury, {align: "right"});
    }
    cury+=5;
    doc.text("Admin Fee", 162, cury, {align: "right"});
    doc.text(formatNumber(data.inv_vatcharge), 198, cury, {align: "right"});
    cury+=5;
    doc.setFont("times", "bold");
    doc.text("Grand Total", 162, cury, {align: "right"});
    doc.text(formatNumber(data.inv_gtotalcharge), 198, cury, {align: "right"});

    curx = 12
    cury+=8;
    
    if(data.payment){
      doc.setDrawColor(0);
      doc.setFillColor(36, 207, 131);
      doc.rect(140, (cury+2) , 39, 9, "F");
      doc.text("PAID", 153, (cury+7.3) );
    }else{
      doc.setDrawColor(0);
      doc.setFillColor(255, 51, 51);
      doc.rect(140, (cury+2) , 39, 9, "F");
      doc.text("UNPAID", 153, (cury+7.3) );
    }
    cury+=7;
    doc.setFont("times", "bold");
    doc.text("Order Details :", curx, cury);
    cury+=4;
    doc.setFont("times", "normal");
    doc.text("Airlines : " + data.invSchedule.vendor.name, curx, cury);
    cury+=4;
    doc.text("Flight Number : " + data.invSchedule.sch_flight_no, curx, cury);
    cury+=4;
    doc.text( `Route : ${data.invSchedule.originport.prt_initial} ( ${data.invSchedule.originport.prt_locationname} ) To  ${data.invSchedule.destinationport.prt_initial} ( ${data.invSchedule.destinationport.prt_locationname} )`, curx, cury)
    // doc.text("Route : "  +  data.invSchedule.originport.prt_initial + " ( " +  data.invSchedule.originport.prt_locationname  +  " ) "  +  " To "  +   data.invSchedule.destinationport.prt_initial + " ( " +  data.invSchedule.destinationport.prt_locationname  +  " ) " , curx, cury);
    cury+=4;
    doc.text("Departure : " + data.invSchedule.sch_departure_datetime, curx, cury);
    cury+=4;
    doc.text("Price per Kg : Rp."  + formatNumber(data.invSchedule.sch_price_kg) , curx, cury);
    if(data.inv_type !== 'TYPINVD' && !data.payment) {
      cury+=8;
      doc.setFont("times", "bold");
      doc.text("Notes :", curx, cury);
      doc.setFont("times", "normal");
      cury+=5;
      doc.text(" - Goods must be in "  + (data.dropship.vnc_configs.dropship.locationname) + " maximum of " + (data.dropship.vnc_configs.dropship.limithour) + " hours before departure time ", curx, cury);
      let datetimeInvoice = moment(data.inv_date, "DD-MM-YYYY HH:mm:ss").add(1, 'hours')
      cury+=5;
      doc.text(" - Your order will be automatically canceled if payment has not yet been fully processed by " + moment(datetimeInvoice).format('DD-MM-YYYY HH:mm:ss') + " o'clock", curx, cury);
    }
    if(data.order.ord_manifestno){
      cury+=8;
      doc.setFont("times", "bold");
      doc.text("SMU# : " + (data.order.ord_manifestno) , curx, cury);
    }

    cury+=9;
    curx+=5;

    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(curx, cury, 78, 35, 3, 3, "FD");

    cury+=5;
    curx+=37;

    doc.setFont("times", "bold");
    doc.text("Make All Payment", curx, cury, {align: "center"});
    cury+=5.3;
    doc.text("Bank Central Asia ACC No. 6043119988", curx, cury, {align: "center"});
    cury+=5.3;
    doc.text("Bank Central Asia KCU Alam Sutra", curx, cury, {align: "center"});
    cury+=5.3;
    doc.text("PT. Berdikari Sejahtera Indonesia", curx, cury, {align: "center"});
    cury+=5.3;
    doc.text("Thank You For Your Business", curx, cury, {align: "center"});
    cury+=5.3;
    doc.text("NPWP No. : 79.627.627.7.416.000", curx, cury, {align: "center"});

    doc.save(pdfName + data.inv_number + '.pdf');

      // window.open(URL.createObjectURL(doc.output("blob")))
  }
}
